import { Avatar, Box, Container, Grid, Stack, Typography } from '@mui/material';
import React from 'react';
import Bg from '../../assets/landing/our-goals.png';
import { Card, Title } from '../../components';
import { OUR_GOALS } from '../../constants/landing';
import { NAVBAR_PAGES } from '../../constants/navbar';

const OurMainGoals = () => {
    return (
        <Box id={NAVBAR_PAGES[1].id}>
            <Title firstPart="Our main" secondaryPart="Goals" />
            <Box
                sx={(theme) => ({
                    backgroundImage: `url(${Bg})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'top left',
                    backgroundSize: 'cover',
                    py: 10,
                })}
            >
                <Container sx={{ alignSelf: 'center' }}>
                    <Container maxWidth="md">
                        <Typography
                            sx={(theme) => ({
                                fontSize: theme.fontSize['2xl'],
                                // fontWeight: theme.fontWeight.light,
                                color: theme.palette.common.white,
                                textAlign: 'justify',
                                textAlignLast: 'center',
                                mt: 10,
                                // lineHeight: 2,
                            })}
                        >
                            The primary objective of this initiative is to empower rural farmers and increase their financial self-reliance.
                            To achieve this goal, the project focuses on a multifaceted approach.
                        </Typography>
                    </Container>
                    <Grid container spacing={5} mt={3} justifyContent="center">
                        {OUR_GOALS.map((item) => (
                            <Grid item md={3.5} sm={6} xs={12}>
                                <Card
                                    sx={(theme) => ({
                                        // background: theme.gradient.secondaryOne,
                                        // backdropFilter: theme.blur.primary,
                                        transition: 'all .3s ease',
                                        height: '100%',
                                    })}
                                >
                                    <Stack alignItems="center" spacing={2}>
                                        <Avatar
                                            variant="square"
                                            src={item.image}
                                            sx={(theme) => ({
                                                transition: 'all .3s ease',
                                                width: 80,
                                                height: 80,
                                            })}
                                        />
                                        <Typography
                                            sx={(theme) => ({
                                                // backgroundColor: theme.palette.primary.main,
                                                color: theme.palette.common.black,
                                                textAlign: 'center',
                                            })}
                                        >
                                            {item.text}
                                        </Typography>
                                    </Stack>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Container>
            </Box>
        </Box>
    );
};

export default OurMainGoals;
