import { BsBarChartLineFill, BsFillBarChartFill, BsFillDisplayFill, BsFillDoorOpenFill, BsFillPeopleFill, BsFillRocketFill } from 'react-icons/bs';
import { FaRecycle } from 'react-icons/fa';
import { HiTrendingUp } from 'react-icons/hi';
import Family from '../assets/landing/our-goals/family.png';
import Farmer from '../assets/landing/our-goals/farmer.png';
import Partner from '../assets/landing/our-goals/partner.png';
import Platform from '../assets/landing/our-goals/platform.png';
import Training from '../assets/landing/our-goals/training.png';

export const OUR_GOALS = [
    {
        image: Family,
        text: 'We assessed 64 family cooperatives.',
    },
    {
        image: Farmer,
        text: 'We have trained over 192 farmers.',
    },
    {
        image: Training,
        text: 'Each evaluated cooperative received a specialized training program.',
    },
    {
        image: Platform,
        text: 'After completing this training, all cooperatives gained access to the platform.',
    },
    {
        image: Partner,
        text: 'We enrolled both public and private partners on the platform to facilitate the process.',
    },
];

export const IMPACT_METRICS_USER = [
    {
        icon: <BsFillDisplayFill />,
        title: 'User Engagement',
        description:
            'The user-friendly online platform effectively engages both producers and consumers, demonstrating success through high participation and positive feedback.',
    },

    {
        icon: <BsFillPeopleFill />,
        title: 'Family Cooperative Development',
        description:
            'Fostering cooperative groups, especially family-based ones, has enhanced collective action and mutual support among farmers, effectively promoting rural development.',
    },
    {
        icon: <BsFillBarChartFill />,
        title: 'Financial Management and Governance',
        description:
            'Participating cooperatives receive training in financial management and governance practices, leading to enhanced transparency, governance, and financial sustainability, all contributing to the long-term viability and self-reliance of rural cooperatives.',
    },
    {
        icon: <HiTrendingUp />,
        title: 'Platform User Growth',
        description:
            "The online platform's success is evident in its consistent growth of users, showcasing its effectiveness in connecting rural producers with consumers, fostering engagement, and expanding its influence within the agricultural and consumer communities.",
    },
];

export const IMPACT_METRICS_FARMER = [
    {
        icon: <BsFillRocketFill />,
        title: 'Enhanced Productivity',
        description:
            'Farmers who adopted sustainable farming practices and received specialized training have experienced notable improvements in crop yields. This increase in productivity signifies a positive shift towards more efficient and sustainable agricultural methods.',
    },

    {
        icon: <BsBarChartLineFill />,
        title: 'Increased Farmer Incomes',
        description:
            'Through direct sales to consumers and the elimination of intermediaries, participating farmers have reported a significant increase in their incomes. This financial empowerment is a key success metric, contributing to the economic well-being of rural communities.',
    },
    {
        icon: <FaRecycle />,
        title: 'Reduction in Food Waste',
        description:
            "Direct access to consumers has enabled farmers to estimate demand more accurately, resulting in a reduction of food waste associated with overproduction. This demonstrates our program's contribution to sustainability in agriculture.",
    },
    {
        icon: <BsFillDoorOpenFill />,
        title: 'Market Access',
        description:
            'By connecting producers with urban consumers, our program has expanded market access for rural farmers. This metric indicates the success of our efforts in overcoming geographical barriers and reaching broader markets.',
    },
];
