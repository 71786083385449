import { Stack } from '@mui/material';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Context from './Context';
import Header from './Header';
import Metrics from './Metrics';
import OurMainGoals from './OurMainGoals';
import Platform from './Platform';

const Landing = () => {
    const { hash } = useLocation();

    useEffect(() => {
        // if not a hash link, scroll to top
        if (hash === '') {
            window.scrollTo(0, 0);
        }
        // else scroll to id
        else {
            setTimeout(() => {
                const id = hash.replace('#', '');
                const element = document.getElementById(id);
                if (element) {
                    window.scrollTo({
                        top: element.offsetTop - 150,
                        left: 0,
                        behavior: 'smooth',
                    });
                }
            }, 0);
        }
    }, [hash]);
    return (
        <Stack spacing={10}>
            <Header />
            <Context />
            <OurMainGoals />
            <Platform />
            <Metrics />
        </Stack>
    );
};

export default Landing;
