import { Divider, Stack, Typography } from '@mui/material';
import React from 'react';

const Title = ({ firstPart, secondaryPart, white }) => {
    return (
        <Stack
            sx={(theme) => ({
                mb: 2,
            })}
            alignItems="center"
        >
            <Stack spacing={1} alignItems="flex-start">
                <Typography
                    sx={(theme) => ({
                        fontSize: theme.fontSize['3xl'],
                        fontWeight: theme.fontWeight.extrabold,
                        color: white ? theme.palette.common.white : theme.palette.primary.main,
                        lineHeight: 1,
                    })}
                >
                    {firstPart}{' '}
                    <Typography
                        component="span"
                        sx={(theme) => ({
                            fontSize: theme.fontSize['6xl'],
                            fontWeight: theme.fontWeight.extralight,
                            color: white ? theme.palette.common.white : theme.palette.primary.main,
                            lineHeight: 1,
                        })}
                    >
                        {secondaryPart}
                    </Typography>
                </Typography>
                <Divider
                    sx={(theme) => ({
                        width: '100%',
                        border: '1px solid',
                        borderColor: white ? theme.palette.common.white : theme.palette.secondary.main,
                        maxWidth: '5rem',
                    })}
                />
            </Stack>
        </Stack>
    );
};

export default Title;
