import { Box, Container, Typography } from '@mui/material';
import React from 'react';
import Bg from '../../assets/landing/header.png';
import { Navbar } from '../../components';

const Header = () => {
    return (
        <Box
            position="relative"
            width="100%"
            sx={{
                height: { md: 750, xs: 850 },
                backgroundImage: `url(${Bg})`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'bottom',
                backgroundSize: 'cover',
            }}
        >
            <Box position="absolute" width="100%" top={0} right={0} zIndex={100}>
                <Navbar />
            </Box>
            <Box position="absolute" width="100%" top="47%" right="50%" zIndex={10} sx={{ transform: 'translate(50%, -50%)' }}>
                <Container maxWidth="md" sx={{ flexGrow: 1 }}>
                    <Typography
                        sx={(theme) => ({
                            fontWeight: theme.fontWeight.extrabold,
                            fontSize: theme.fontSize['6xl'],
                            color: theme.palette.common.white,
                            textAlign: 'center',
                            lineHeight: 1.2,
                            mb: 3,
                        })}
                    >
                        Empowering Rural Agriculture through Family Cooperatives
                    </Typography>
                    <Typography
                        sx={(theme) => ({
                            fontSize: theme.fontSize['xl'],
                            color: theme.palette.common.white,
                            textAlign: 'center',
                        })}
                    >
                        In response to the rural-agricultural gap, the Social Innovation Lab launched a project aiming to empower farmers,
                        boost productivity, and achieve financial autonomy for rural communities.
                    </Typography>
                </Container>
            </Box>
        </Box>
    );
};

export default Header;
