import { Box, Container, Grid, Stack, Typography, alpha } from '@mui/material';
import React from 'react';
import { Footer, IconCardLight, Title } from '../../components';
import { IMPACT_METRICS_FARMER, IMPACT_METRICS_USER } from '../../constants/landing';
import { NAVBAR_PAGES } from '../../constants/navbar';

const Metrics = () => {
    return (
        <Box id={NAVBAR_PAGES[3].id}>
            <Title firstPart="Impact" secondaryPart="Metrics" />
            <Box
                sx={(theme) => ({
                    background: alpha(theme.palette.secondary.main, 0.125),
                    py: 10,
                    mt: 3,
                })}
            >
                <Container sx={{ alignSelf: 'center' }}>
                    <Typography
                        sx={(theme) => ({
                            fontSize: theme.fontSize['2xl'],
                            // fontWeight: theme.fontWeight.extralight,
                            color: theme.palette.common.black,
                            mb: 10,
                        })}
                    >
                        User Engagement and Cooperative Development
                    </Typography>

                    <Grid container spacing={10}>
                        {IMPACT_METRICS_USER.map((item, index) => (
                            <Grid item md={3} sm={6} xs={12} key={index}>
                                <Stack spacing={3} alignItems="center">
                                    <IconCardLight color="secondary" icon={item.icon} />
                                    <Typography
                                        sx={(theme) => ({
                                            fontSize: theme.fontSize.md,
                                            fontWeight: theme.fontWeight.medium,
                                            textAlign: 'center',
                                        })}
                                    >
                                        {item.title}
                                    </Typography>
                                </Stack>
                            </Grid>
                        ))}
                    </Grid>
                </Container>
            </Box>

            {/* ----------------------- */}

            <Box
                sx={(theme) => ({
                    background: alpha(theme.palette.muted.main, 0.125),
                    py: 10,
                })}
            >
                <Container sx={{ alignSelf: 'center' }}>
                    <Typography
                        sx={(theme) => ({
                            fontSize: theme.fontSize['2xl'],
                            // fontWeight: theme.fontWeight.extralight,
                            color: theme.palette.common.black,
                            mb: 10,
                        })}
                    >
                        Farmer Incomes and Productivity
                    </Typography>

                    <Grid container spacing={10}>
                        {IMPACT_METRICS_FARMER.map((item, index) => (
                            <Grid item md={3} sm={6} xs={12} key={index}>
                                <Stack spacing={3} alignItems="center">
                                    <IconCardLight color="muted" icon={item.icon} />
                                    <Typography
                                        sx={(theme) => ({
                                            fontSize: theme.fontSize.md,
                                            fontWeight: theme.fontWeight.medium,
                                            textAlign: 'center',
                                        })}
                                    >
                                        {item.title}
                                    </Typography>
                                </Stack>
                            </Grid>
                        ))}
                    </Grid>
                </Container>
            </Box>
            <Footer />
        </Box>
    );
};

export default Metrics;
