export const NAVBAR_PAGES = [
    {
        title: 'Context',
        id: 'context',
    },
    {
        title: 'Our main goals',
        id: 'our-main-goals',
    },
    {
        title: 'The Platform',
        id: 'nos-chaines-de-valeur',
    },
    {
        title: 'Impact Metrics',
        id: 'impact-metrics',
    },
];
