import { Container, Grid, Typography } from '@mui/material';
import React from 'react';
import { Img, Title } from '../../components';
import Image from '../../assets/landing/platform.png';
import { NAVBAR_PAGES } from '../../constants/navbar';

const Platform = () => {
    return (
        <Container sx={{ alignSelf: 'center' }} id={NAVBAR_PAGES[2].id}>
            <Title firstPart="The User-Friendly " secondaryPart="Platform" />
            <Grid container spacing={5} alignItems="center">
                <Grid item md={6} xs={12}>
                    <Typography
                        component="span"
                        sx={(theme) => ({
                            fontSize: theme.fontSize.lg,
                            fontWeight: theme.fontWeight.light,
                            color: theme.palette.common.black,
                            lineHeight: 2,
                        })}
                    >
                        At the heart of this endeavor lies a user-friendly online platform designed to bridge the gap between producers and
                        consumers. This platform serves as a digital marketplace, enabling rural agriculture producers to showcase their
                        products directly to consumers
                    </Typography>
                </Grid>
                <Grid item md={6} xs={12}>
                    <Img src={Image} />
                </Grid>
            </Grid>
        </Container>
    );
};

export default Platform;
