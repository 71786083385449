import { Box, Container, Grid, Typography } from '@mui/material';
import React from 'react';
// import Bg from '../../assets/background/blob-scatter-1.png';
import { Img, Title } from '../../components';
import Image from '../../assets/landing/context.png';
import { NAVBAR_PAGES } from '../../constants/navbar';

const Context = () => {
    return (
        <Box id={NAVBAR_PAGES[0].id}>
            <Container sx={{ alignSelf: 'center' }}>
                <Title firstPart="The" secondaryPart="Context" />
                <Grid container spacing={5} alignItems="center">
                    <Grid item md={6} xs={12}>
                        <Img src={Image} />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Typography
                            component="span"
                            sx={(theme) => ({
                                fontSize: theme.fontSize.lg,
                                fontWeight: theme.fontWeight.light,
                                color: theme.palette.common.black,
                                lineHeight: 2,
                            })}
                        >
                            In recent years, rural agricultural communities have faced a significant challenge – the need to bridge the gap
                            between producers and consumers. Recognizing the critical importance of addressing this issue, the Social
                            Innovation Lab initiated a comprehensive project titled "Bridging the Gap: Empowering Rural Agriculture through
                            Family Cooperatives." This project takes a holistic approach to empower rural farmers, enhance their
                            productivity, and ensure their financial autonomy.
                        </Typography>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};

export default Context;
